import React from 'react';

// Components
import { Box } from '@mui/material';
import { CircularProgress } from '@mui/material';

const Loading = () => {
    
    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="loadingWrapper">
                <div className="text-center font-3 pb-3">loading...</div>
                <Box className="d-flex justify-content-center" sx={{display: "flex"}}>
                    <CircularProgress fontSize="large" disableShrink/>
                </Box>
            </div>
        </div>
    );
};

export default Loading;
