import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI components
import StepContent from '@mui/material/StepContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import StepLabel from '@mui/material/StepLabel';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Select from '@mui/material/Select';
import Step from '@mui/material/Step';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';


// MUI icons
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// Components
import Loading from '../components/loading';
import ToolPreview from '../components/visualizationTool/toolPreview';

import { ENDPOINT_PATH } from '../config';
import './checkout.css';


const noFrameName = "noSelectedFrame";
const states = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO',
'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];




const Checkout = (props) => {
    const navigate = useNavigate();

    // Get user
    const user = JSON.parse(localStorage.getItem('user'));

    // Loading values
    const [loadingFinalProductsList, setLoadingFinalProductsList] = useState(true);
    const [loadingFetchedFinalProducts, setLoadingFetchedFinalProducts] = useState(true);
    let loading = loadingFinalProductsList || loadingFetchedFinalProducts;

    // Shopping cart
    const [finalProductList, setFinalProductList] = useState([]);
    const [shoppingCartID, setShoppingCartID] = useState("");
    const [fetchedFinalProducts, setFetchedFinalProducts] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    // Values for stepper
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ["Review", "Shipping", "Pay"];

    // Values for address fields
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [goodFirstName, setGoodFirstName] = useState(true);
    const [goodLastName, setGoodLastName] = useState(true);
    const [allFieldsValid, setAllFieldsValid] = useState(false);

    const [shippingCost, setShippingCost] = useState(0);


    // -------------- SHOPPING CART ---------------
    // UseEffect to get shopping cart contents
    useEffect(() => {
        const getShoppingCart = () => {
            fetch(ENDPOINT_PATH + `/api/shopping-cart?userID=` + encodeURIComponent(user.uid))
            .then(response => response.json())
            .then(data => {
                setShoppingCartID(data.shoppingCartID ?? "");
                setFinalProductList(data.finalProducts ?? []);
                setLoadingFinalProductsList(false);
            })
            .catch(e => {
                console.log(e);
                setLoadingFinalProductsList(false);
            })
        }

        if (shoppingCartID === "" && user) {
            getShoppingCart();
        }
        else {
            setLoadingFinalProductsList(false);
        }
        
    }, [shoppingCartID, user]);


    // UseEffect to fetch the data of all the final products
    useEffect(() => {
        const fetchFinalProductsData = async () => {
            let priceRunningTotal = 0;
            const FinalProducts = await Promise.all(
                // Map through the list of final products and query each one, since right now we
                //     only have a reference to each one, and not the actual values they contain
                finalProductList.map(async (finalProductObject) => {
                    const quantity = finalProductObject.quantity;
                    const finalProduct = finalProductObject.finalProduct;
                    const finalProductID = finalProduct._path.segments[1];

                    // Query to find the data of the final product with the given ID
                    try {
                        const finalProductRes = await fetch(ENDPOINT_PATH + `/api/final-product?finalProductID=${encodeURIComponent(finalProductID)}`);
                        const data = await finalProductRes.json();

                        // Add price of finalProduct to running total
                        const finalProductTotalPrice = data.mediumPrice + data.framePrice + data.matboardPrices[0] + data.matboardPrices[1] + data.matboardPrices[2] + data.glassPrice + data.hangPrice;
                        priceRunningTotal += finalProductTotalPrice * quantity;
                        const finalProduct = {
                            finalProductID: finalProductID,
                            quantity: quantity,
                            finalProduct: data,
                            price: finalProductTotalPrice
                        }
                        return finalProduct;
                    } catch (error) {
                        console.error("Error fetching final product data:", error);
                        return null;
                    }
                })
            );
            // Filter out the ones that we failed to fetch
            setFetchedFinalProducts(FinalProducts.filter(item => item !== null));

            setTotalPrice(priceRunningTotal);

            setLoadingFetchedFinalProducts(false);
        };

        fetchFinalProductsData();

    }, [finalProductList]);


    // Method to delete final product from shopping cart
    const deleteFinalProduct = async (finalProductID) => {
        // TODO: include an "are you sure" popup
        try {
            const deleteFinalProductRes = await fetch(ENDPOINT_PATH + '/api/delete-final-product', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    shoppingCartID: shoppingCartID,
                    finalProductID: finalProductID
                }),
            });
            if (deleteFinalProductRes.ok) {
                console.log("Successfully removed final product from shopping cart");

                // Update the finalProductList to the return value of the API call, which is the new state of the array in the database
                setFinalProductList(await deleteFinalProductRes.json());
            }
            else {
                console.error('Error deleting final product:', deleteFinalProductRes.statusText);
            }

        } catch (e) { console.log(e); }
    }


    // Method to edit the quantity of a final product in the shopping cart
    const changeFinalProductQuantity = async (finalProductID, newQuantity) => {
        try {
            const changeFinalProductQuantityRes = await fetch(ENDPOINT_PATH + '/api/change-final-product-quantity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    shoppingCartID: shoppingCartID,
                    finalProductID: finalProductID,
                    newQuantity: newQuantity
                }),
            });
            if (changeFinalProductQuantityRes.ok) {
                console.log("Successfully changed final product quantity in shopping cart");

                // Update the finalProductList to the return value of the API call, which is the new state of the array in the database
                setFinalProductList(await changeFinalProductQuantityRes.json());
            }
            else {
                console.error('Error changing final product quantity:', changeFinalProductQuantityRes.statusText);
            }

        } catch (e) { console.log(e); }
    }


    // Method to handle editing a final product
    const handleEditFinalProduct = (finalProductObject) => {
        // Add final product ID to local storage so that when we get to the vis tool, it knows we're
        //     editing a finalProduct as opposed to creating a new one
        window.localStorage.setItem('FINAL_PRODUCT_ID', JSON.stringify(finalProductObject.finalProductID));

        // Add all the other necessary cached values so the finalProduct loads correctly into the visTool
        window.localStorage.setItem('MEDIUM', JSON.stringify(finalProductObject.medium));
        window.localStorage.setItem('UPLOADED_IMAGE', JSON.stringify(finalProductObject.uploadedImage));
        window.localStorage.setItem('UPLOADED_IMAGE_FILE_NAME', JSON.stringify(finalProductObject.uploadedImageFileName));
        window.localStorage.setItem('IMAGE_SIZE', JSON.stringify(finalProductObject.imageSize));
        window.localStorage.setItem('FRAME_ORIENTATION', JSON.stringify(finalProductObject.frameOrientation));
        window.localStorage.setItem('FRAME_IMAGE', JSON.stringify(finalProductObject.frameImage));
        window.localStorage.setItem('FRAME_THICKNESS_AVAILABLE', JSON.stringify(finalProductObject.frameThicknessAvailable));
        window.localStorage.setItem('SELECTED_FRAME', JSON.stringify(finalProductObject.selectedFrame));
        window.localStorage.setItem('DISPLAY_NAME', JSON.stringify(finalProductObject.displayName));
        window.localStorage.setItem('FRAME_THICKNESS', JSON.stringify(finalProductObject.frameThickness));
        window.localStorage.setItem('FRAME_TYPE', JSON.stringify(finalProductObject.frameType));
        window.localStorage.setItem('FRAME_SIZE', JSON.stringify(finalProductObject.frameSize));
        window.localStorage.setItem('FRAME_IMAGE_SIZE', JSON.stringify(finalProductObject.frameImageSize));
        window.localStorage.setItem('MATBOARD_NUM', JSON.stringify(finalProductObject.matboardNum));
        window.localStorage.setItem('MATBOARD_THICKNESS', JSON.stringify(finalProductObject.matboardThickness));
        window.localStorage.setItem('SELECTED_MATBOARDS', JSON.stringify(finalProductObject.selectedMatboards));
        window.localStorage.setItem('GLASS_TYPE', JSON.stringify(finalProductObject.glassType));
        window.localStorage.setItem('HANGING_STYLE', JSON.stringify(finalProductObject.hangingStyle));

        navigate('/visualizationTool');
    }


    // ---------------- STEPPER ----------------

    // Helper methods for the stepper
    const handleNext = async () => {
        if (activeStep === 1) { // Check if the current step is "Shipping"
            // Save address information to local storage
            localStorage.setItem('FIRST_NAME', JSON.stringify(firstName));
            localStorage.setItem('LAST_NAME', JSON.stringify(lastName));
            localStorage.setItem('ADDRESS_LINE_1', JSON.stringify(addressLine1));
            localStorage.setItem('ADDRESS_LINE_2', JSON.stringify(addressLine2));
            localStorage.setItem('CITY', JSON.stringify(city));
            localStorage.setItem('STATE', JSON.stringify(state));
            localStorage.setItem('POSTAL_CODE', JSON.stringify(postalCode));
    
            // Call FedEx API to calculate shipping cost
            const recipient = {
                streetLines: [addressLine1, addressLine2].filter(line => line),
                city: city,
                stateOrProvinceCode: state,
                postalCode: postalCode,
                countryCode: 'US'
            };
            try {
                const response = await fetch(ENDPOINT_PATH + '/api/fedex-rates', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ recipient })
                });
                if (response.ok) {
                    const data = await response.json();
                    // Extract the totalNetFedExCharge value from the response
                    const totalNetFedExCharge = data.output.rateReplyDetails[0].ratedShipmentDetails[0].totalNetFedExCharge;
                    setShippingCost(totalNetFedExCharge * fetchedFinalProducts.length);
                } else {
                    console.error('Failed to calculate shipping cost:', await response.text());
                }
            } catch (error) {
                setShippingCost(0);
                console.error('Failed to calculate shipping cost');
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };



    // ---------------- SHIPPING ----------------

    const hasNumbers = (str) => {
        return /\d/.test(str);
    }


    useEffect(() => {
        if(hasNumbers(firstName)) {
            // invalid first name
            console.log("bad first name");
            setGoodFirstName(false);
        }
        else {
            // valid first name
            console.log("good first name");
            setGoodFirstName(true);
        }
    }, [firstName]);


    useEffect(() => {
        if(hasNumbers(lastName)) {
            // invalid last name
            console.log("bad last name");
            setGoodLastName(false);
        }
        else {
            // valid last name
            console.log("good last name");
            setGoodLastName(true);
        }
    }, [lastName]);


    // Check if all inputs are filled in and valid
    useEffect(() => {
        setAllFieldsValid(firstName    !== '' && goodFirstName &&
                          lastName     !== '' && goodLastName &&
                          addressLine1 !== '' &&
                          city         !== '' &&
                          state        !== '' &&
                          postalCode   !== '');
    }, [firstName, goodFirstName, lastName, goodLastName, addressLine1, city, state, postalCode]);

    
    // ------------ CHECKOUT ----------------

    // Helper method for checkout
    const handleStripeCheckout = async () => {
        


        // Logic to create a Stripe checkout session and redirect to Stripes
        const response = await fetch(ENDPOINT_PATH + '/api/create-checkout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "amount": Math.round(totalWithShippingAndLaborAndTax * 100) // Convert to cents and round to nearest integer
            })
        });

        if (!response.ok) {
            console.error('Response not OK:', await response.text());
            return;
        }

        const session = await response.json();
        window.location.href = session;
    };


    const laborCost = totalPrice * 0.4;
    const salesTax = totalPrice * 0.07;
    const totalWithShippingAndLaborAndTax = totalPrice + shippingCost + laborCost + salesTax;


    return (
        <div className={`${props.scrollLock ? "position-fixed" : ""}`}>
            <div className="visToolHeaderImage SmallestHeader mb-5"/>
            <div className="row d-flex justify-content-center ps-5">
                <div className="col-md-8 mb-4 ps-5">
                    <Stepper className="checkoutWrapper pt-5" activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => {
                            return (
                                <Step key={`step ${steps[index]}`}>
                                    <StepLabel>{steps[index]}</StepLabel>
                                    <StepContent>
                                        {/* Step 1: Review */}
                                        {index === 0 &&
                                            <div className="card mb-4">
                                                {loading &&
                                                <Loading/>
                                                }
                                                {!loading && fetchedFinalProducts.length === 0 &&
                                                <>
                                                    <div className="d-flex row justify-content-center p-5">
                                                        <div className="font-3 subheader">There's nothing here!</div>
                                                    </div>
                                                    <div className="d-flex row justify-content-center p-5">
                                                        <div className="font-2 subheader pb-3">Want to make a frame?</div>
                                                        <br/>
                                                        <a href="/visualizationTool" className="subheader">
                                                        <button className="btn btn-outline-light btn-lg button-padding button-dark">
                                                                <div className="font-2">start building</div>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </>
                                                }
                                                {!loading &&
                                                    <div>
                                                        {fetchedFinalProducts.map((finalProductObject, index) => {
                                                            const finalProduct = finalProductObject.finalProduct;
                                                            return (
                                                                <div className="d-flex row pb-4" key={`checkout-final-product ${index}`}>
                                                                    {/* Item overview */}
                                                                    <Box className="shoppingCartItem">
                                                                        {/* Title */}
                                                                        <div className="row ps-3 pt-3">
                                                                            <div className="font-2">{finalProduct.title}</div>
                                                                        </div>

                                                                        <div className="row ps-5 pt-2">
                                                                            {/* Tool preview */}
                                                                            <div className="col-2">
                                                                                <ToolPreview
                                                                                    uploadedImage={finalProduct.uploadedImage}
                                                                                    frameOrientation={finalProduct.frameOrientation}
                                                                                    frame={finalProduct.frameImage}
                                                                                    matboards={{thickness: finalProduct.matboardThickness, colors: [finalProduct.selectedMatboards[0].hexColor,
                                                                                                                                                    finalProduct.selectedMatboards[1].hexColor,
                                                                                                                                                    finalProduct.selectedMatboards[2].hexColor]}}
                                                                                    frameThickness={finalProduct.frameThickness}
                                                                                    frameSize={finalProduct.frameSize}
                                                                                    frameImageSize={finalProduct.frameImageSize}
                                                                                    matboardNum={finalProduct.matboardNum}
                                                                                    sameSize={true}
                                                                                />
                                                                            </div>
                                                                            
                                                                            <div className="col pt-2">
                                                                                <div className="row">
                                                                                    {/* Quantity field */}
                                                                                    <div className="col d-flex align-items-center justify-content-center">
                                                                                        <div className="pe-3">quantity</div>
                                                                                        <TextField
                                                                                            type="number"
                                                                                            variant="outlined"
                                                                                            value={finalProductObject.quantity}
                                                                                            className="w40"
                                                                                            size={"small"}
                                                                                            onChange={(event) => { changeFinalProductQuantity(finalProductObject.finalProductID, event.target.value); } }
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-9 pt-1 ps-3">
                                                                                        <div className="row">
                                                                                            {/* Edit button */}
                                                                                            <div className="col-1 pe-5">
                                                                                                <div onClick={(e) => { handleEditFinalProduct(finalProductObject); } }>
                                                                                                    <EditIcon className="materialIcon"/>
                                                                                                </div>
                                                                                            </div>

                                                                                            {/* Delete button */}
                                                                                            <div className="col d-flex justify-content-end pe-5">
                                                                                                <div onClick={(e) => { deleteFinalProduct(finalProductObject.finalProductID); } }>
                                                                                                    <DeleteIcon className="materialIcon"/>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Price */}
                                                                                    <div className="d-flex justify-content-end pt-5 pe-5">
                                                                                        <div className="bold font-3">${(finalProductObject.price).toFixed(2)}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Box>

                                                                    {/* Component breakdown */}
                                                                    <div className="ps-5">
                                                                        <div className="pt-2 ps-5">
                                                                            <Divider sx={{width: "92%", opacity: 1}}/>
                                                                            {/* IMAGE */}
                                                                            <div className="d-flex row">
                                                                                {finalProduct.medium !== "" && (
                                                                                    <div className="d-flex p-2">
                                                                                        <img className="col-2" src={finalProduct.uploadedImage} alt="uploaded print"/>
                                                                                        <div className="ps-3 d-flex align-items-center">{finalProduct.imageSize.width}" x {finalProduct.imageSize.height}" {finalProduct.medium}</div>
                                                                                        <div className="ps-4 d-flex align-items-center">${finalProduct.mediumPrice.toFixed(2)}</div>
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                            {/* FRAME */}
                                                                            <div className="d-flex row ">
                                                                                {finalProduct.selectedFrame !== noFrameName && (
                                                                                    <div className="d-flex p-2">
                                                                                        <img className="col-2" src={finalProduct.frameImage} alt="uploaded frame"/>
                                                                                        <div className="ps-3 d-flex align-items-center">{finalProduct.displayName} Frame</div>
                                                                                        <div className="ps-4 d-flex align-items-center">${finalProduct.framePrice.toFixed(2)}</div>
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                            {/* MATBOARDS */}
                                                                            <div className="d-flex row ">
                                                                                {(Array.from({ length: finalProduct.matboardNum }, (_, index) => index)).map((layerIndex) => {
                                                                                    return (
                                                                                        <div className="d-flex ps-2 py-2" key={`matboard-options-dropdown-shopping-cart ${layerIndex}`}>
                                                                                            <div className="ps-2 col col-sm-auto">
                                                                                                <svg width={50} height={50}>
                                                                                                    <circle stroke="black" strokeWidth="1" cx={25} cy={25} r={20} fill={finalProduct.selectedMatboards[layerIndex].hexColor} />
                                                                                                </svg> 
                                                                                            </div>
                                                                                            <div className="ps-4 d-flex align-items-center">{finalProduct.selectedMatboards[layerIndex].displayName} Matboard</div>
                                                                                            <div className="ps-4 d-flex align-items-center">${finalProduct.matboardPrices[layerIndex].toFixed(2)}</div>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>

                                                                            {/* ACCESSORIES */}
                                                                            <div className="d-flex row">
                                                                            {(finalProduct.glassType !== "None" && finalProduct.hangingStyle !== "None") && (
                                                                                <>
                                                                                    {finalProduct.glassType !== "None" &&
                                                                                        <div className="d-flex row py-3">
                                                                                            <div className="d-flex">
                                                                                                <div className="ps-3 pe-4">{finalProduct.glassType} Glass</div>
                                                                                                <div className="ps-5">${finalProduct.glassPrice.toFixed(2)}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {finalProduct.hangingStyle !== "None" &&
                                                                                        <div className="d-flex row pt-3 pb-4">
                                                                                            <div className="d-flex">
                                                                                                <div className="ps-3 pe-4">{finalProduct.hangingStyle} Hanging</div>
                                                                                                <div className="ps-5">${finalProduct.hangPrice.toFixed(2)}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </>
                                                                            )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Divider className="ms-3 pt-3" sx={{width: "97%", opacity: 1}}/>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                }

                                                {/* Next button */}
                                                <div className="pt-4 pb-5 pe-5 d-flex justify-content-end">
                                                    <button className={`btn button-dark px-4 py-2 font-2 ${fetchedFinalProducts.length === 0 && "disabled"}`} onClick={handleNext} disabled={fetchedFinalProducts.length === 0}>next</button>
                                                </div>
                                            </div>
                                        }

                                        {/* Step 2: Shipping */}
                                        {index === 1 &&
                                            <div className="card mb-4">
                                                <div className="card-header py-3">
                                                    <h5 className="mb-0">Shipping Information</h5>
                                                </div>
                                                <div className="card-body p-4">
                                                    <form>
                                                        <div className="row mb-2">
                                                            <div className="col">
                                                                <TextField
                                                                    id="firstName"
                                                                    label="first name"
                                                                    variant="outlined"
                                                                    type="name"
                                                                    className="w100"
                                                                    value={firstName}
                                                                    error={!goodFirstName}
                                                                    helperText={!goodFirstName ? "a name can't have numbers" : " " }
                                                                    onChange={(e) => { setFirstName(e.target.value); }}
                                                                />
                                                            </div>

                                                            <div className="col">
                                                                <TextField
                                                                    id="lastName"
                                                                    label="last name"
                                                                    variant="outlined"
                                                                    type="name"
                                                                    className="w100"
                                                                    value={lastName}
                                                                    error={!goodLastName}
                                                                    helperText={!goodLastName ? "a name can't have numbers" : " " }
                                                                    onChange={(e) => { setLastName(e.target.value); }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="mb-4">
                                                            <TextField
                                                                id="addressLine1Input" 
                                                                label="address line 1" 
                                                                variant="outlined" 
                                                                type="text"
                                                                className="w100"
                                                                value={addressLine1}
                                                                onChange={(e) => setAddressLine1(e.target.value)}
                                                            />
                                                        </div>

                                                        <div className="mb-4">
                                                            <TextField
                                                                id="addressLine2Input"
                                                                label="address line 2 (optional)"
                                                                variant="outlined"
                                                                type="text"
                                                                className="w100"
                                                                value={addressLine2}
                                                                onChange={(e) => setAddressLine2(e.target.value)}
                                                            />

                                                        </div>

                                                        <div className="row mb-4">
                                                            <div className="col">
                                                                <TextField
                                                                    id="cityInput"
                                                                    label="city"
                                                                    variant="outlined"
                                                                    type="text"
                                                                    className="w100"
                                                                    value={city}
                                                                    onChange={(e) => setCity(e.target.value)}
                                                                />
                                                            </div>

                                                            <div className="col">
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">state</InputLabel>
                                                                    <Select
                                                                        label="state"
                                                                        id="stateInput"
                                                                        value={state}
                                                                        onChange={(e) => setState(e.target.value)}
                                                                    >
                                                                        {states.map((stateOption, index) => {
                                                                            return (
                                                                                <MenuItem key={index} value={stateOption}>
                                                                                    {stateOption}
                                                                                </MenuItem>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                </FormControl>
                                                                
                                                            </div>

                                                            <div className="col">
                                                                <TextField
                                                                    id="postalCodeInput"
                                                                    label="ZIP code"
                                                                    variant="outlined"
                                                                    type="text"
                                                                    className="w100"
                                                                    value={postalCode}
                                                                    onChange={(e) => setPostalCode(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                                {/* Back/next buttons */}
                                                <div className="py-4 px-5 d-flex justify-content-between">
                                                    <button className="btn button-dark px-4 py-2 font-2" onClick={handleBack}>back</button>
                                                    <button className={`btn button-dark px-4 py-2 font-2 ${!allFieldsValid && "disabled"}`} onClick={handleNext} disabled={!allFieldsValid}>next</button>
                                                </div>
                                            </div>
                                        }

                                        {/* Step 3: Payment */}
                                        {index === 2 &&
                                            <div className="card mb-4">
                                                <div className="card-body p-4">
                                                    <div className="pt-2 d-flex justify-content-between">
                                                        <div>Subtotal</div>
                                                        <div>${totalPrice.toFixed(2)}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between pt-3">
                                                        <div>Cost of Labor (40% of subtotal)</div>
                                                        <div>${laborCost.toFixed(2)}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between pt-3">
                                                        <div>Sales Tax (7% of subtotal)</div>
                                                        <div>${salesTax.toFixed(2)}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between pt-3 pb-3">
                                                        <div>Shipping with FedEx Express Saver</div>
                                                        <div>${shippingCost.toFixed(2)}</div>
                                                    </div>
                                                    <p className="pt-2" style={{ fontSize: 'smaller' }}>Disclaimer: Currently, MyCustomFrame only offers the 3-day FedEx Express Saver shipping option</p>

                                                    <hr/>

                                                    <div className="d-flex justify-content-between pt-3 font-3 bold">
                                                        <div>Total</div>
                                                        <div>${totalWithShippingAndLaborAndTax.toFixed(2)}</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="p-5 d-flex justify-content-between">
                                                        <div>
                                                            <button className="btn button-dark px-4 py-2 font-2" onClick={handleBack}>back</button>
                                                        </div>
                                                        <div>
                                                        <button className="btn button-dark font-2" onClick={handleStripeCheckout}>Pay with Stripe</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </StepContent>
                                </Step>
                            )
                        })}
                    </Stepper>
                </div>
            </div>
        </div>
    );
};



export default Checkout;
