import React, { useState } from 'react';
import { getAuth, sendEmailVerification, createUserWithEmailAndPassword } from "firebase/auth";
import { ENDPOINT_PATH } from '../../config';

import TextField from '@mui/material/TextField';

// Icons
import CloseIcon from '@mui/icons-material/Close';


const MobileRegister = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');


    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        const auth = getAuth();

        createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
            console.log("Successfully created user with email and password");

            const user = userCredential.user;

            sendEmailVerification(auth.currentUser)
            .then(async () => {
                console.log("Verification email sent to", user.email);
                console.log('User registered successfully:', user);
                console.log("User verification status: ", user.emailVerified);

                try {
                    // Add user to Users collection in Firestore
                    const registerRes = await fetch(ENDPOINT_PATH + '/api/register', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            uid: user.uid,
                            email: email,
                            firstName: firstName,
                            lastName: lastName
                        }),
                    });
                    if (registerRes.ok) {
                        console.log("Successfully registered user");

                        try {
                            // Create a shopping cart for the user
                            const shoppingCartRes = await fetch(ENDPOINT_PATH + '/api/create-shopping-cart', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    userID: user.uid
                                }),
                            });
                            if (shoppingCartRes.ok) {
                                console.log('Successfully created shopping cart for new user');

                                // Update user in local storage
                                localStorage.setItem('user', JSON.stringify(user));

                                // Set all states to blank
                                setError('');
                                setFirstName('');
                                setLastName('');
                                setEmail('');
                                setPassword('');
                                setConfirmPassword('');

                                // Reload page
                                window.location.reload();
                            }
                            else {
                                console.error('Error creating shopping cart for new user:', shoppingCartRes.statusText);
                            }
                        } catch (error) {
                            console.error('Error creating shopping cart for new user:', error);
                        }

                    }
                    else {
                        setError("Error adding user to database, please try again later");
                        console.error('Error registering user:', registerRes.statusText);
                    }
                } catch (error) {
                    setError("Error adding user to database, please try again later");
                    console.error('Error adding user to Firestore:', error);
                }
            })
            .catch((error) => {
                setError("Error sending email verification, please try again later");
                console.error('Error verifying user', error);
            });
        })
        .catch((error) => {
            setError("Error creating user with email and password, please try again later");
            console.error('Error creating user with email and password:', error);
        });
    };

    return (
        <div className="mobileLoginForm">
            <div className="pt-3 row">
                <form className='col' onSubmit={handleSubmit}>
                    <div >
                        <div className="pt-3 ps-4">
                            <CloseIcon className="CloseLogin"  fontSize={"large"} onClick={props.handleRegisterClose}/>
                        </div>
                    </div>
                    <h2 className='text-center pt-5 header pb-2'>sign up</h2>
                    <div className='pt-5 px-5'>
                        <div className="pb-4">
                            <TextField 
                                id="first-name"
                                label="first name"
                                variant="outlined" 
                                type="name"
                                className="w100"
                                value={firstName} 
                                onChange={handleFirstNameChange} 
                                required
                            />
                        </div>
                        <div className="pb-4" >
                            <TextField 
                                id="last-name"
                                label="last name"
                                variant="outlined" 
                                type="name"
                                className="w100"
                                value={lastName} 
                                onChange={handleLastNameChange} 
                                required
                            />
                        </div>
                        <div className="pb-4" >
                            <TextField 
                                id="email"
                                label="email"
                                variant="outlined" 
                                type="email"
                                className="w100"
                                value={email} 
                                onChange={handleEmailChange} 
                                required
                            />
                        </div>
                        <div className="pb-4" >
                            <TextField 
                                id="password"
                                label="password"
                                variant="outlined" 
                                type="password"
                                className="w100"
                                value={password} 
                                onChange={handlePasswordChange} 
                                required
                            />
                        </div>
                        <div className="pb-4" >
                            <TextField 
                                id="password"
                                label="confirm password"
                                variant="outlined" 
                                type="password"
                                className="w100"
                                value={confirmPassword} 
                                onChange={handleConfirmPasswordChange}
                                required
                            />
                        </div>
                        <div className="d-flex justify-content-center pt-4 pb-5">
                            <button type="submit" className="btn btn-danger btn-lg w-100">register</button>
                        </div>
                        {error && <p style={{color: 'red'}}>{error}</p>}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MobileRegister;
