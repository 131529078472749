import React, { useState } from 'react';
import emailjs from '@emailjs/browser';


import Footer from '../components/Footer';
import '../App.css';

//
import TextField from '@mui/material/TextField';

const OrderForm = (props) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");


    const sendEmailforRequest = (e) => {
        e.preventDefault();
        console.log(e.target);

        emailjs.send('service_ooc7j77', 'template_utbnb2o', {
            from_name: name,
            email_from: email,
            message: message
        }, 'nL6YWuxefh8cOwWQ_').then(
            (response) => {
              console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
              console.log('FAILED...', error);
            },
        );
    }


    return (
        <div className={`${props.scrollLock ? "position-fixed" : ""}`}>
            <div className="d-flex align-items-center ContactHeaderImage SubHeader vstack">
                <div className="subFont text-center">custom orders</div>
                <div className="subHeaderFont text-center">want a unique frame not found on our site?</div>
            </div>
            <div className="wrapper">
                <form onSubmit={sendEmailforRequest}>
                    <div className="pt-5 pb-4" htmlFor="from_name">
                        <TextField 
                            id="from_name"
                            label="name"
                            variant="outlined" 
                            type="text"
                            className="w100"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="pb-4" htmlFor="emai_from">
                        <TextField 
                            id="email_from"
                            variant="outlined" 
                            label="email"
                            type="text"
                            className="w100"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="pb-4" htmlFor="message">
                        <TextField 
                            id="message" 
                            variant="outlined" 
                            type="text"
                            className="w100"
                            label="your custom request"
                            multiline
                            rows={8}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>

                    <div className="d-flex justify-content-end pt-4">
                        <button className="btn btn-lg button-dark font-3" > send </button>
                    </div>

                </form>
            </div>

            <Footer />

        </div>
    );
};
  
export default OrderForm;
