import { React } from 'react';
import xmark from '../images/x.jpg';

/* This version is currently only styled on desktop versions */

const CheckoutFail = (props) => {
    return (
        <div>
            <div className="popup">
                <div className="checkout-popup-inner row">
                    <div className="col pb-5">
                        <div className="checkout-successFail-wrapper">
                            <div className="checkout-successFail-icon">
                                <img src={xmark} height="100" width="100" alt="x icon"/>
                            </div>
                            <div className="checkout-successFail-text">
                                <h2>
                                    Order unsuccessful
                                </h2>
                                <h6>
                                    Your order was unsuccessful. Please check the details of your order and try again.
                                </h6>
                            </div>
                            <div className="text-center pt-2">
                                <a href="/">
                                    <button className="btn button-dark">Back to MyCustomFrame</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckoutFail;
