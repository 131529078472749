import {React } from 'react';
import checkmark from '../images/checkmark.png';

/* This version is currently only styled on desktop versions */

const CheckoutSuccess = (props) => {
    return (
            <div className="popup">
                <div className="checkout-popup-inner row">
                    <div className="col pb-5">
                        <div className="checkout-successFail-wrapper">
                            <div className="checkout-successFail-icon">
                                <img src={checkmark} height="60" width="60" alt="checkmark icon"/>
                            </div>
                            <div className="checkout-successFail-text">
                                <h2>
                                    Thank you for your order!
                                </h2>
                                <h6 >
                                    Your order has been successfully completed. The MyCustomFrame team will
                                    reach out to you shortly regarding your order.
                                </h6>
                            </div>
                            <div className="text-center pt-2">
                                <a href="/">
                                    <button className="btn button-dark">Back to MyCustomFrame</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
    );
}

export default CheckoutSuccess;
