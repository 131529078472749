import React, { useState, useEffect } from 'react';
import { getAuth, sendEmailVerification, createUserWithEmailAndPassword } from "firebase/auth";

// MUI components
import TextField from '@mui/material/TextField';

// MUI Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

import "./register.css";
import { ENDPOINT_PATH } from '../../config';



const RegisterForm = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [goodFirstName, setGoodFirstName] = useState(true);
    const [goodLastName, setGoodLastName] = useState(true);
    const [goodEmail, setGoodEmail] = useState(true);
    const [goodPassword, setGoodPassword] = useState(true);
    const [goodConfirmPassword, setGoodConfirmPassword] = useState(true);
    const [allFieldsValid, setAllFieldsValid] = useState(true);
    const [error, setError] = useState('');



    const handleSubmit = async (e) => {
        e.preventDefault();

        const auth = getAuth();

        createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
            console.log("Successfully created user with email and password");

            const user = userCredential.user;

            sendEmailVerification(auth.currentUser)
            .then(async () => {
                console.log("Verification email sent to", user.email);
                console.log('User registered successfully:', user);
                console.log("User verification status: ", user.emailVerified);

                try {
                    // Add user to Users collection in Firestore
                    const registerRes = await fetch(ENDPOINT_PATH + '/api/register', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            uid: user.uid,
                            email: email,
                            firstName: firstName,
                            lastName: lastName
                        }),
                    });
                    if (registerRes.ok) {
                        console.log("Successfully registered user");

                        try {
                            // Create a shopping cart for the user
                            const shoppingCartRes = await fetch(ENDPOINT_PATH + '/api/create-shopping-cart', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    userID: user.uid
                                }),
                            });
                            if (shoppingCartRes.ok) {
                                console.log('Successfully created shopping cart for new user');

                                // Update user in local storage
                                localStorage.setItem('user', JSON.stringify(user));

                                // Set all states to blank
                                setError('');
                                setFirstName('');
                                setLastName('');
                                setEmail('');
                                setPassword('');
                                setConfirmPassword('');

                                props.verifyReminder();
                            }
                            else {
                                console.error('Error creating shopping cart for new user:', shoppingCartRes.statusText);
                            }
                        } catch (error) {
                            console.error('Error creating shopping cart for new user:', error);
                        }

                    }
                    else {
                        setError("Error adding user to database, please try again later");
                        console.error('Error registering user:', registerRes.statusText);
                    }
                } catch (error) {
                    setError("Error adding user to database, please try again later");
                    console.error('Error adding user to Firestore:', error);
                }
            })
            .catch((error) => {
                setError("Error sending email verification, please try again later");
                console.error('Error verifying user', error);
            });
        })
        .catch((error) => {
            setError("Error creating user with email and password, please try again later");
            console.error('Error creating user with email and password:', error);
        });
    };


    // ---------------Validation----------------
    const hasNumbers = (str) => {
        return /\d/.test(str);
    };

    // First name validation
    useEffect(() => {
        if(hasNumbers(firstName)) {
            // invalid first name
            console.log("bad first name");
            setGoodFirstName(false);
        } 
        else {
            // valid first name
            console.log("good first name");
            setGoodFirstName(true);
        }
    }, [firstName]);

    // Last name validation
    useEffect(() => {
        if(hasNumbers(lastName)) {
            // invalid last name
            console.log("bad last name");
            setGoodLastName(false);
        } 
        else {
            // valid last name
            console.log("good last name");
            setGoodLastName(true);
        }
    }, [lastName]);

    // Email validation
    useEffect(() => {
        if(/\S+@\S+\.\S+/.test(email.trim()) || email === '') {
            console.log("good email");
            setGoodEmail(true);
        }
        else {
            // valid email
            console.log("bad email");
            setGoodEmail(false);
        }
    }, [email]);

    // Password validation
    useEffect(() => {
        if(password.length >= 6 || password === '') {
            setGoodPassword(true);
        }
        else {
            setGoodPassword(false);
        }
    }, [password]);

    // Confirm password validation
    useEffect(() => {
        if(confirmPassword === password || confirmPassword === '') {
            setGoodConfirmPassword(true);
        }
        else {
            setGoodConfirmPassword(false);
        }
    }, [confirmPassword]);

    // Check if all inputs are filled in and valid
    useEffect(() => {
        setAllFieldsValid(firstName       !== '' && goodFirstName &&
                          lastName        !== '' && goodLastName &&
                          email           !== '' && goodEmail &&
                          password        !== '' && goodPassword &&
                          confirmPassword !== '' && goodConfirmPassword);
    }, [firstName, goodFirstName, lastName, goodLastName, email, goodEmail, password, goodPassword, confirmPassword, goodConfirmPassword]);



    return (
        <div className="popup">
            <div className="popup-inner">
                <form className='col p-0' onSubmit={handleSubmit}>
                    <div className="row pt-2 px-4">
                        <div className="col d-flex close-btn pt-2" onClick={props.handleRegisterClose}>
                            <ArrowBackIcon className="materialIcon" fontSize="large"/>
                        </div>
                        <div className="col d-flex justify-content-end close-btn pt-2" onClick={props.handleLoginClose}>
                            <CloseIcon className="materialIcon" fontSize="large"/>
                        </div>
                    </div>
                    <h2 className='d-flex justify-content-center pt-4 header pb-4'>sign up</h2>
                    <div className='px-5 col col-lg-auto pb-3'>
                        <div className="row">
                            <div className="col">
                                <TextField 
                                    id="firstName"
                                    label="first name"
                                    variant="outlined"
                                    type="name"
                                    className="w100"
                                    value={firstName}
                                    error={!goodFirstName}
                                    helperText={!goodFirstName ? "a name can't contain numbers" : " " }
                                    onChange={(e) => { setFirstName(e.target.value); }}
                                />
                            </div>

                            <div className="col">
                                <TextField
                                    id="lastName"
                                    label="last name"
                                    variant="outlined"
                                    type="name"
                                    className="w100"
                                    value={lastName}
                                    error={!goodLastName}
                                    helperText={!goodLastName ? "a name can't contain numbers" : " " }
                                    onChange={(e) => { setLastName(e.target.value); }}
                                />
                            </div>
                        </div>
                        <div className="pt-1">
                            <TextField
                                id="emailInput"
                                label="email"
                                variant="outlined"
                                type="email"
                                className="form-control"
                                value={email}
                                error={!goodEmail}
                                helperText={!goodEmail ? "invalid email format" : " " }
                                onChange={(e) => { setEmail(e.target.value); }}
                            />
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextField
                                    id="password"
                                    label="password"
                                    variant="outlined"
                                    type="password"
                                    className="w100"
                                    value={password}
                                    error={!goodPassword}
                                    helperText={!goodPassword ? "password must be at least 6 characters long" : " " }
                                    onChange={(e) => { setPassword(e.target.value); }}
                                />
                            </div>

                            <div className="col">
                                <TextField
                                    id="confirmPassword"
                                    label="confirm password"
                                    variant="outlined"
                                    type="password"
                                    className="w100"
                                    value={confirmPassword}
                                    error={!goodConfirmPassword}
                                    helperText={!goodConfirmPassword ? "passwords must match" : " " }
                                    onChange={(e) => { setConfirmPassword(e.target.value); }}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center py-4">
                            <button type="submit" className={`btn btn-danger btn-lg w-100 ${!allFieldsValid && "disabled"}`} disabled={!allFieldsValid}>register</button>
                        </div>
                        {error && <p style={{color: 'red'}}>{error}</p>}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegisterForm;
