import React, { useState, useEffect} from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from '../../firebase';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { ENDPOINT_PATH } from '../../config';

import TextField from '@mui/material/TextField';

// Icons
import CloseIcon from '@mui/icons-material/Close';

import MobileRegister from '../Register/mobileRegister';
import "./login.css";


const MobileLogin = (props) => {
    const [useRegister, setUseRegister] = useState(false);
    const [useLogin, setUseLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);

    // Check if the user is already authenticated on component mount
    useEffect(() => {
        // Set persistence to LOCAL
        setPersistence(auth, browserLocalPersistence)
        .then(() => {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                setUser(user);
                console.log('User is logged in:', user ? 'Yes' : 'No');
            });

            const storedUser = JSON.parse(localStorage.getItem('user'));
            if (storedUser) {
                setUser(storedUser);
                console.log('User is logged in:', storedUser ? 'Yes' : 'No');
            }

            // Cleanup function to unsubscribe the listener when the component unmounts
            return () => unsubscribe();
        })
        .catch((error) => {
            console.error('Error setting persistence:', error);
        });
    }, []);

    const toggleRegisterForm = () => {
        setUseLogin(!useLogin);
        setUseRegister(!useRegister);
    }

    const toggleLoginForm = () => {
        setUseRegister(false);
        setUseLogin(true);
        props.handleClose();
    }

    const handleLogin = async (e) => {
        e.preventDefault();

        // Trim input values
        const trimmedEmail = email.trim();
        const trimmedPassword = password.trim();
    
        // Log trimmedEmail for debugging
        console.log('Trimmed Email:', trimmedEmail);

        // Validate email format
        if (!isValidEmail(trimmedEmail)) {
            console.error('Invalid email format. Please enter a valid email address.');
            return;
        }

        try {
            const userCredential = await signInWithEmailAndPassword(auth, trimmedEmail, trimmedPassword);
            const user = userCredential.user;

            // Update local state
            setUser(user);

            // Update user in local storage
            localStorage.setItem('user', JSON.stringify(user));

            console.log('User logged in successfully:', user);
            console.log('User UID:', user.uid);

            // Reload page
            window.location.reload();
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.error('Error logging in:', errorCode, errorMessage);
        }
    };


    // Basic email format validation
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };
    

    // Taken from Firebase documentation
    const handleGoogleSignIn = async (e) => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();

        signInWithPopup(auth, provider)
        .then(async (userCredential) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(userCredential);
        const token = credential.accessToken;
        const user = userCredential.user;

        // Update local state
        setUser(user);

        // Update user in local storage
        localStorage.setItem('user', JSON.stringify(user));

        console.log('User logged in successfully:', user);
        console.log('User UID:', user.uid);

        // Reload page
        window.location.reload();
        
        // Create shopping cart
        try {
            // Create a shopping cart for the user
            const shoppingCartRes = await fetch(ENDPOINT_PATH + '/api/create-shopping-cart', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userID: user.uid
                }),
            });
            if (shoppingCartRes.ok) {
                console.log('Successfully created shopping cart for new user');


                // Reload page
                window.location.reload();
            }
            else {
                console.error('Error creating shopping cart for new user:', shoppingCartRes.statusText);
            }
        } catch (error) {
            console.error('Error creating shopping cart for new user:', error);
        }

        // Catch google sign in error
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
        });

    }

    const handleClose = () => {
        props.handleClose(user); // Pass the user state back to the Navbar component
    };

    const Login = (
        <div className="px-4 mobileLoginForm">
            <div>
                <form className="pt-5">
                    <div className="row pt-4">
                        <div>
                            <CloseIcon className="CloseLogin" fontSize={"large"} onClick={handleClose}/>
                        </div>
                    </div>
                    <h2 className='text-center py-5 header'>welcome back!</h2>
                    <div className='col pt-4'>
                        <div className="pt-2">
                            <div className="pb-3" >
                                <TextField 
                                    id="email"
                                    label="email"
                                    variant="outlined" 
                                    type="email"
                                    className="w100"
                                    value={email}
                                    onChange={(e) =>setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="pt-3">
                            <div className="pt-1 pb-4" >
                                <TextField 
                                    id="password"
                                    label="password"
                                    variant="outlined" 
                                    type="password"
                                    className="w100"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)} 
                                    required
                                />
                            </div>
                        </div>
                        <div className="pt-1 pb-5">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                            <label className="form-check-label ps-2" htmlFor="exampleCheck1">Remember Me</label>
                        </div>
                        <div className="d-flex justify-content-center py-4">
                            <button type="submit" className="btn btn-primary btn-lg w-100" onClick={handleLogin}>Log In</button>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="google-sign-in-button w-100" onClick={handleGoogleSignIn}>Sign in with Google</button>
                        </div>
                        <div className="pb-5">
                            <div className="d-flex justify-content-center py-4">
                                <button type="button" className="btn btn-danger btn-lg w-100" onClick={toggleRegisterForm}>Register</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
    
    return (
        <div>
            {useLogin && Login}
            {useRegister && <MobileRegister handleRegisterClose={toggleRegisterForm} handleLoginClose={toggleLoginForm}/> }
        </div>
    );
};

export default MobileLogin;
