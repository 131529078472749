import { useState, useEffect, React } from 'react';
import Footer from '../components/Footer';
import '../App.css';
import { 
    updateProfile,
    updateEmail,
    getAuth,
    updatePassword,
    sendPasswordResetEmail,
    reauthenticateWithCredential,
    EmailAuthProvider
} from "firebase/auth";
import { ENDPOINT_PATH } from '../config';

// Material UI components
import TextField from '@mui/material/TextField';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';



const Account = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user == null) {
        window.location.href = './';
    }
    
    const [currentSection, setCurrentStep] = useState("Dashboard");
    const [userProfileInfo, setUserProfileInfo] = useState({firstName: "", lastName: "", email: ""});

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState(' ');
    const [newPassword, setNewPassword] = useState(' ');
    const [confirmNewPassword, setConfirmNewPassword] = useState(' ');

    // Format checkers
    const [goodEmail, setGoodEmail] = useState(true);
    const [goodFirstName, setGoodFirstName] = useState(true);
    const [goodLastName, setGoodLastName] = useState(true);
    const [goodCurrentPassword, setGoodCurrentPassword] = useState(true);
    const [goodNewPassword, setGoodNewPassword] = useState(true);
    const [goodConfirmNewPassword, setGoodConfirmNewPassword] = useState(true);

    const [storedUser, setStoredUser] = useState(null);

    // Get the user info to fill in the default values of the forms
    useEffect(() => {
        const getUserInformation = () => {
            fetch(ENDPOINT_PATH + `/api/get-user-info?user=` + encodeURIComponent(user.uid))
            .then(response => response.json())
            .then(data => { 
                setUserProfileInfo({ firstName: data.firstName, lastName: data.lastName, email: data.email });
            })
            .catch(e => { console.error(e); });
        }

        getUserInformation();

        setStoredUser(getAuth().currentUser);
        //console.log(storedUserest);

        console.log("--------------------------");
        console.log(storedUser);
        console.log("--------------------------");
        console.log(user);
    }, []);

    useEffect(() => {
        if(hasNumbers(firstName)) {
            // invalid email
            console.log("bad first name");
            setGoodFirstName(false);
        } 
        else {
            // valid email
            console.log("good first name");
            setGoodFirstName(true);
        }
    }, [firstName]);

    useEffect(() => {
        if(hasNumbers(lastName)) {
            // invalid email
            console.log("bad last name");
            setGoodLastName(false);
        } 
        else {
            // valid email
            console.log("good last name");
            setGoodLastName(true);
        }
    }, [lastName]);

    useEffect(() => {
        if(newPassword === confirmNewPassword) {
            // valid email
            console.log("New passwords do match");
            setGoodNewPassword(true);
            setGoodConfirmNewPassword(true);
        } 
        else {
            // invalid email
            console.log("New passwords don't match");
            setGoodNewPassword(false);
            setGoodConfirmNewPassword(false);
        }
    }, [newPassword, confirmNewPassword]);


    // Profile Update Functions

    const updateUserEmail = async() => {
        console.log("test")
        // // Update the email in the user side - (This is different from the collection)
        // await updateEmail(user, email).then(() => {
        //     console.log("Email updated");
        //     // Email updated!
        //     // ...
        // }).catch((error) => {
        //     console.error("Error on updating Email");
        //     // An error occurred
        //     // ...
        // });
        // // Update the email on the collection side
        // fetch(ENDPOINT_PATH + `/api/user-update-name?email=` + encodeURIComponent(email) + `&user=` + encodeURIComponent(user.uid),{
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': "application/json; charset=UTF-8", // <-- Specifying the Content-Type
        //     },
        // })
        // .then(response => response.json()).then(data => { console.log(data); })
        // .catch(e => { console.error(e); });
    }
    
    const updateUserPassword = async () => {
        
        // If check if the current password matches the one in the database
        const credential = EmailAuthProvider.credential(user.email, currentPassword);

        const correctPasswordCheck = await reauthenticateWithCredential(storedUser, credential)
        .then(() => { 
            console.log("Correct Password"); 
            return true;
        })
        .catch((error) => { 
            console.error("Incorrect Password: " + error); 
            return false; 
        });

        if(correctPasswordCheck === true) {
            // Check the new password
            // If it fits the correct formating
            setGoodCurrentPassword(true);
            if(passwordFormatCheck()) {
                setGoodConfirmNewPassword(true);
                // Update the password
                //await updatePassword(storedUser, newPassword)
                //.then(() => { console.log("Password has been changed") })
                //.catch((error) => { console.error("Error on password change" + error) });

                // Clear the entries in the forms
                setCurrentPassword("");
                setNewPassword("");
                setConfirmNewPassword("");
            }
            else {
                // If it doesn't meet the correct formatting
                setGoodNewPassword(false);
            }
        }
        else {
            // If it doesn't send an error to the current password text field
            console.log("Wrong password");
            setGoodCurrentPassword(false);

        }
    }

    const updateUserName = async () => {
        // console.log(storedUser);
        // Update the name in the user side - (This is different from the collection)
        await updateProfile(storedUser, { displayName: firstName.concat(" ", lastName) })
        .then(() => { 
            //console.log("Name updated") 
        })
        .catch((error) => { 
            //console.error("Error on name change: " + error) 
        });

        // Update the name on the collection side
        fetch(ENDPOINT_PATH + `/api/user-update-name?firstName=` + encodeURIComponent(firstName) + `&lastName=` 
        + encodeURIComponent(lastName) + `&user=` + encodeURIComponent(user.uid),
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json; charset=UTF-8", // <-- Specifying the Content-Type
            },
        })
        .then(response => response.json()).then(data => { 
            //console.log(data); 
        })
        .catch(e => { console.error(e); });
        // Either reload the page or reload the text fields
        //window.location.reload();
    }

    // Helper Functions
    const hasNumbers = (str) => {
        return /\d/.test(str);
    };

    const handleChange = (event, newValue) => {
        setCurrentStep(newValue);
    };

    const emailFormatCheck = () => {
        let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
        if(!emailRegex.test(email)) {
            // invalid email
            console.log("bad email");
            setGoodEmail(false);
        } 
        else {
            // valid email
            console.log("good email");
            setGoodEmail(true);
        }
    }

    const hasUpperCase = (str) => {
        return /[A-Z]/.test(str);
    }
    const hasSpecialCharacter = (str) => {
        return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
    }

    const passwordFormatCheck = () => {
        // Rules for password -
        // Must be at least 10 characters long
        // Must contain one uppercase letter
        // Must contain at least one number and special character
        return (hasUpperCase(newPassword) && hasSpecialCharacter(newPassword) && hasNumbers(newPassword));

        // if(hasUpperCase(newPassword) && hasSpecialCharacter(newPassword) && hasNumbers(newPassword)) {
        //     console.log("Password contains all the necessary parts");
        //     return true;
        // }
        // else {
        //     console.log("Password contains all the necessary parts");

        //     return false;
        // }
    }



    return (
        <div className={`${props.scrollLock ? "position-fixed" : ""}`}>
            <div className="d-flex align-items-center SubHeaderImage SmallestHeader vstack mb-4" />
            <div className="d-flex justify-content-center pt-4 sectionTitle-Light">account settings</div>

            <Box className="pt-5" sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 150 }} >
                <TabContext value={currentSection} >
                    <div className="wrapperPadding">
                        <TabList
                            orientation="vertical"
                            value={currentSection}
                            onChange={handleChange}
                            sx={{ borderRight: 1, borderColor: 'divider', width:200 }}
                            >
                            <Tab label="Dashboard" value="Dashboard" className="py-4"/>
                            <Tab label="Account Settings" value="Account Settings" className="py-4"/>
                        </TabList>
                    </div>

                    <div className="ps-5">


                        <TabPanel value="Dashboard">
                            <div>
                                <div className="font-5 bold"> welcome back, {userProfileInfo.firstName} {userProfileInfo.lastName}! </div>
                                <div>
                                    <div>
                                        Order history
                                    </div>
                                </div>
                            </div>
                        </TabPanel>




                        <TabPanel value="Account Settings">
                            <div className="tabPadding">
                                <div>
                                    <div className="font-4 bold pb-3">Update Account Information</div>

                                    <div className="font-2 bold py-3">Name</div>
                                    <div className="row">
                                        <div className="col-2">
                                            <TextField 
                                                id="firstName" 
                                                label="first name" 
                                                variant="outlined" 
                                                type="name"
                                                error={!goodFirstName}
                                                helperText={!goodFirstName ? "A name can't have numbers" : " " }
                                                onChange={(e) => { setFirstName(e.target.value); }}
                                            />
                                        </div>
                                        <div className="col-2">
                                            <TextField 
                                                id="lastName" 
                                                label="last name" 
                                                variant="outlined" 
                                                type="name"
                                                error={!goodLastName}
                                                helperText={!goodLastName ? "A name can't have numbers" : " " }
                                                onChange={(e) => { setLastName(e.target.value); }}
                                            />
                                        </div>
                                        <div className="col-5 pt-1">
                                            <button 
                                                className="btn button-dark font-2" 
                                                disabled={((!goodFirstName || !goodLastName) || (firstName === "" || lastName === "")) ? true: false} 
                                                variant="outlined" 
                                                onClick={updateUserName}
                                            >
                                                update name
                                            </button>
                                        </div>
                                    </div>

                                    <div className="font-2 bold py-3">Email</div>
                                    <div className="row">
                                        <div className="col-3">
                                            <TextField 
                                                id="email" 
                                                label={user.email} 
                                                //error={emailFormatCheck ? true : false}
                                                variant="outlined" 
                                                type="email" 
                                                error={!goodEmail}
                                                helperText={!goodEmail ? "Enter a vaild email format" : " " }
                                                onChange={(e) => {
                                                    setEmail(e.target.value); 
                                                    emailFormatCheck();
                                                }}
                                            />
                                        </div>
                                        <div className="col pt-1">
                                            <button className="btn button-dark font-2" variant="outlined" disabled={!goodEmail || email === ""} onClick={updateUserEmail}>update email</button>
                                        </div>

                                    </div>
                                  
                                    <div className="font-2 bold py-3"> Password </div>
                                    <div className="row pb-2">
                                        <div className="col-3">
                                            <TextField 
                                                id="currentPassword" 
                                                label="Current Password" 
                                                variant="outlined" 
                                                type="password"
                                                value={currentPassword}
                                                error={!goodCurrentPassword}
                                                helperText={!goodCurrentPassword ? "Wrong password" : " " }
                                                onChange={(e) => {
                                                    setCurrentPassword(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-2">
                                            <TextField 
                                                id="newPassword" 
                                                label="New Password" 
                                                value={newPassword}
                                                variant="outlined" 
                                                type="password"
                                                error={!goodNewPassword}
                                                onChange={(e) => {
                                                    setNewPassword(e.target.value); 
                                                }}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <TextField 
                                                id="confirmNewPassword" 
                                                label="Confirm New Password"
                                                value={confirmNewPassword}
                                                variant="outlined" 
                                                type="password" 
                                                error={!goodConfirmNewPassword}
                                                helperText={!goodConfirmNewPassword ? "New passwords must match" : " " }
                                                onChange={(e) => {
                                                    setConfirmNewPassword(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col">
                                            <button 
                                                className="btn button-dark font-2" 
                                                disabled={((!goodNewPassword || !goodConfirmNewPassword) || (newPassword === "" || confirmNewPassword === "")) ? true: false} 
                                                variant="outlined" 
                                                onClick={updateUserPassword}
                                            >
                                                update password
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>


                    </div>
                </ TabContext >
            </Box>

            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            <div className="py-5"/>
            

            <Footer />
        </div>
    )
}

export default Account;
