import React, { useState } from 'react';
import './App.css';
import Navbar from './components/NavBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


// Pages
import Home from './pages/home';
import About from './pages/about';
import ArtistGallery from './pages/artistGallery';
import OrderForm from './pages/orderForm';
import VisualizationTool from './components/visualizationTool/visTool';
import Account from './pages/account';
import Checkout from './pages/checkout';
import CheckoutSuccess from './pages/checkoutSuccess';
import CheckoutFail from './pages/checkoutFail';

function App() {
  const [scrollLock, setScrollLock] = useState(false);
  
  return (
    <Router>
      <Navbar
        toggleScrollLock = {() => {
            setScrollLock(!scrollLock);
            console.log("scrollLock = ", !scrollLock);
          }
        }
      />
      <Routes>
        <Route path='/' element = {<Home scrollLock={scrollLock} />} />
        <Route path='/about' element = {<About scrollLock={scrollLock} />} />
        <Route path='/order-form' element = {<OrderForm scrollLock={scrollLock} />} />
        <Route path='/artist-gallery' element = {<ArtistGallery scrollLock={scrollLock} />} />
        <Route path='/visualizationTool' element = {<VisualizationTool scrollLock={scrollLock} />} />
        <Route path='/account' element = {<Account scrollLock={scrollLock} />} />
        <Route path='/checkout' element = {<Checkout scrollLock={scrollLock} />} />
        <Route path='/checkoutSuccess' element = {<CheckoutSuccess scrollLock={scrollLock} />} />
        <Route path='/checkoutFail' element = {<CheckoutFail scrollLock={scrollLock} />} />
      </Routes>
    </Router>
  );
}

export default App;
