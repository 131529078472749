import React, { useState, useEffect} from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from '../../firebase';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';

// MUI components
import TextField from '@mui/material/TextField';

// MUI icons
import CloseIcon from '@mui/icons-material/Close';

// Components
import RegisterForm from '../Register';

import "./login.css";
import { ENDPOINT_PATH } from '../../config';



const LoginForm = (props) => {
    const [useRegister, setUseRegister] = useState(false);
    const [useLogin, setUseLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [goodEmail, setGoodEmail] = useState(true);
    const [allFieldsValid, setAllFieldsValid] = useState(false);
    const [badLogin, setBadLogin] = useState(false);

    const [user, setUser] = useState(null);

    const toggleRegisterForm = () => {
        setUseLogin(!useLogin);
        setUseRegister(!useRegister);
    }

    const toggleLoginForm = () => {
        setUseRegister(false);
        setUseLogin(true);
        props.handleClose();
    }

    // Check if the user is already authenticated on component mount
    useEffect(() => {
        // Set persistence to LOCAL
        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                    setUser(user);
                    console.log('User is logged in:', user ? 'Yes' : 'No');
                });

                const storedUser = JSON.parse(localStorage.getItem('user'));
                if (storedUser) {
                    setUser(storedUser);
                    console.log('User is logged in:', storedUser ? 'Yes' : 'No');
                }

                // Cleanup function to unsubscribe the listener when the component unmounts
                return () => unsubscribe();
            })
            .catch((error) => {
                console.error('Error setting persistence:', error);
            });
    }, []);


    useEffect(() => {
        if(/\S+@\S+\.\S+/.test(email.trim()) || email === '') {
            console.log("good email");
            setGoodEmail(true);
        }
        else {
            // valid email
            console.log("bad email");
            setGoodEmail(false);
        }
    }, [email]);


    // Check if all inputs are filled in and valid
    useEffect(() => {
        setAllFieldsValid(email !== '' && goodEmail && password !== '');
    }, [email, goodEmail, password]);



    const handleLogin = async (e) => {
        e.preventDefault();

        // Trim input values
        const trimmedEmail = email.trim();
        const trimmedPassword = password.trim();
    
        // Log trimmedEmail for debugging
        console.log('Trimmed Email:', trimmedEmail);

        try {
            const userCredential = await signInWithEmailAndPassword(auth, trimmedEmail, trimmedPassword);
            const user = userCredential.user;

            // Update local state
            setUser(user);

            // Update user in local storage
            localStorage.setItem('user', JSON.stringify(user));

            console.log('User logged in successfully:', user);
            console.log('User UID:', user.uid);

            // Reload page
            window.location.reload();
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;

            setBadLogin(true);

            console.error('Error logging in:', errorCode, errorMessage);
        }
    };


    // Taken from Firebase documentation
    const handleGoogleSignIn = async (e) => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();

        signInWithPopup(auth, provider)
        .then(async (userCredential) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(userCredential);
            const token = credential.accessToken;
            const user = userCredential.user;

            // Update local state
            setUser(user);

            // Update user in local storage
            localStorage.setItem('user', JSON.stringify(user));

            console.log('User logged in successfully:', user);
            console.log('User UID:', user.uid);

            // Reload page
            window.location.reload();
            
            // Create shopping cart
            try {
                // Create a shopping cart for the user
                const shoppingCartRes = await fetch(ENDPOINT_PATH + '/api/create-shopping-cart', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userID: user.uid
                    }),
                });
                if (shoppingCartRes.ok) {
                    console.log('Successfully created shopping cart for new user');


                    // Reload page
                    window.location.reload();
                }
                else {
                    console.error('Error creating shopping cart for new user:', shoppingCartRes.statusText);
                }
            } catch (error) {
                console.error('Error creating shopping cart for new user:', error);
            }

        // Catch google sign in error
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
        });

    }


    const handleClose = () => {
        props.handleClose(user); // Pass the user state back to the Navbar component
    };

    const Login = (
        <div className="popup">
            <div className="popup-inner">
                <div className="loginWrapper row">
                    <div className="col testImage2" />
                    <form className='col'>
                        <div className="row d-flex justify-content-end">
                            <div className="close-btn pt-2">
                                <CloseIcon className="materialIcon" fontSize="large" onClick={handleClose}/>
                            </div>
                        </div>
                        <h2 className='text-center pt-3 font-4 bold'>welcome back!</h2>
                        <div className='ps-4 text-center col'>
                            <div className="pt-1">
                                <TextField
                                    id="emailInput"
                                    label="email"
                                    variant="outlined"
                                    type="email"
                                    value={email}
                                    error={!goodEmail}
                                    helperText={!goodEmail ? "invalid email format" : " " }
                                    onChange={(e) => { setEmail(e.target.value); }}
                                />
                            </div>
                            <div>
                                <TextField
                                    id="passwordInput"
                                    label="password"
                                    variant="outlined"
                                    type="password"
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value); }}
                                />
                            </div>
                            <div className="form-check pt-1">
                                <input type="checkbox" className="form-check-input" id="rememberMeCheck" />
                                <label className="ps-1 form-check-label" htmlFor="rememberMeCheck">remember me</label>
                            </div>
                            <div className="d-flex row justify-content-center py-2">
                                {badLogin &&
                                    <div className="font-1 d-flex row justify-content-center errorMessage">
                                        <div className="d-flex justify-content-center">invalid login details,</div>
                                        <div className="d-flex justify-content-center">please try again</div>
                                    </div>
                                }
                                <button type="submit" className={`btn button-dark w-100 ${!allFieldsValid && "disabled"}`} onClick={handleLogin} disabled={!allFieldsValid}>log in</button>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="google-sign-in-button w-100" onClick={handleGoogleSignIn}>sign in with Google</button>
                            </div>
                            <div>
                                <div className="d-flex row justify-content-center py-4">
                                    <div className="font-1 d-flex justify-content-center pb-1">don't have an account?</div>
                                    <button type="button" className="btn btn-danger btn-lg w-100" onClick={toggleRegisterForm}>sign up</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    
                </div>
                
            </div>
        </div>
    );
    

    return (

        <div>
            {useLogin && Login}
            {useRegister && <RegisterForm handleRegisterClose={toggleRegisterForm} handleLoginClose={toggleLoginForm} verifyReminder={props.verifyReminder}/> }
        </div>

    );
};

export default LoginForm;
