// Import the functions you need from the SDKs you need
import { getApp, getApps, initializeApp } from "firebase/app"; // Firebase web SDK
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";


import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB8JTEnZU3vmHoN_Lcv9x5gJfA2d0TZ3CU",
  authDomain: "mycustomframe-eeb5c.firebaseapp.com",
  projectId: "mycustomframe-eeb5c",
  storageBucket: "mycustomframe-eeb5c.appspot.com",
  messagingSenderId: "457371732882",
  appId: "1:457371732882:web:11c1e14e2964df98db0f4b",
  measurementId: "G-LQ367VJ0GM"
};


// Initialize Firebase
const app = getApps().length ? getApp() : initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);



// Export firestore database
// It will be imported into your react app whenever it is needed
const db = getFirestore(app);
export const imageStorage = getStorage(app);
export const auth = getAuth(app);


// getDownloadURL(ref(imageStorage, 'frames/16_20_.png'))
//   .then((url) => {
//     // `url` is the download URL for 'images/stars.jpg'

//     // This can be downloaded directly:
//     const xhr = new XMLHttpRequest();
//     xhr.responseType = 'blob';
//     xhr.onload = (event) => {
//       const blob = xhr.response;
//     };
//     xhr.open('GET', url);
//     xhr.send();

//     // Or inserted into an <img> element
//     const img = document.getElementById('myimg');
//     img.setAttribute('src', url);
//   })
//   .catch((error) => {
//     // Handle any errors
//   });
 