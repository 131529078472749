import React from 'react';
import '../App.css';
import Footer from '../components/Footer';

// Images
import productsImage from "../images/frame-home.jpg";


const artistGallery = (props) => {
    return (
        <div className={`${props.scrollLock ? "position-fixed" : ""}`}>
            <div className="d-flex align-items-center SubHeaderImage SubHeader vstack">
                <div className="subFont text-center">artist alley</div>
                <div className="subHeaderFont text-center">browse the works of featured artists</div>
            </div>

            <div className="wrapper">
                <div className="d-flex justify-content-center pt-5 sectionTitle-Light">trending</div>
                <div className="d-flex justify-content-center pt-5 sectionFont-Light">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultricilisis.</div>

            </div>

            <div>
                <div className="row">
                    <div className="col-6 ">
                        <div className="artistHomeImage head-text">
                        <a href="/products">
                            <img src={productsImage} alt="Products"/>
                        </a>
                        <div className="text-on-image">
                            <div className='sectionTitle-Dark row'>collections</div>
                            <div className='sectionFont-Dark row'>filler text</div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="wrapper">
                <div className="d-flex justify-content-center pt-5 sectionTitle-Light">new</div>
                <div className="d-flex justify-content-center pt-5 sectionFont-Light">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultricilisis.</div>

            </div>

            <div className="wrapper">
                <div className="d-flex justify-content-center pt-5 sectionTitle-Light">new</div>
                <div className="d-flex justify-content-center pt-5 sectionFont-Light">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultricilisis.</div>

            </div>

            

            <div className="wrapper">
                <div className="d-flex justify-content-center pt-5 sectionTitle-Light text-center">want unique frames?</div>
                <div className="d-flex justify-content-center pt-3 sectionFont-Light text-center">You can contact us about more specific frames if you can't find the 
                right size for you here. </div>
                <div className="d-flex justify-content-center sectionFont-Light pt-5">
                <a href="/order-form">
                    <button className="d-flex btn btn-outline-dark btn-lg">Custom Orders</button>
                </a>
                </div>
            </div>

            <Footer />

        </div>
    );
};
  
export default artistGallery;
