import React, {useState, useEffect } from 'react';
import { auth } from '../../firebase';

import LoginForm from '../Login/index';
import ShoppingCart from '../Cart/ShoppingCart.js'
import MobileLogin from '../Login/mobileLogin.js';
import VerifyEmail from '../Register/confirmEmailPopup.js';

// Images
import logoImage from '../../images/Logo-Base-basic.png';

// Icons
import { FaBars } from 'react-icons/fa';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';


const Navbar = (props) => {
    const [useLogin, setUseLogin] = useState(false);
    const [useShoppingCart, setUseShoppingCart] = useState(false);
    const [useVerifyReminder, setVerifyReminder] = useState(false);
    const [user, setUser] = useState(null);
    const isLoggedIn = user !== null;
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [showDiv, setShowDiv] = useState(false);
    const [userUid, setUserUid] = useState(null);
 

    // ------------------------------------- UseEffects -------------------------------------
    // UseEffect to get user
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            setUserUid(user ? user.uid : null);
        });

        const storedUser = JSON.parse(localStorage.getItem('user'));
        console.log("storedUser:", storedUser);
        
        setUser(storedUser);
        setUserUid(storedUser ? storedUser.uid : null);

        return () => unsubscribe();
    }, []);


    // ------------------------------------- Helper methods -------------------------------------
    const toggleLoginForm = (user) => {
        setUseLogin(!useLogin);
        props.toggleScrollLock();
    }

    const toggleShoppingCart = () => {
        setUseShoppingCart(!useShoppingCart);
        props.toggleScrollLock();
    }

    const toggleVerifyReminder = () => {
        setVerifyReminder(!useVerifyReminder);
    }

    const useAccountSettings = () => {
        window.location.href = "/account";
    }

    const handleLogout = async (e) => {

        e.preventDefault();

        try {
            await auth.signOut();

            // Update the local state to reflect the user is logged out
            setUser(null);
            setUserUid(null);

            // Remove user from local storage
            localStorage.removeItem('user');

            console.log('Logout successful');

            // Reload page
            window.location.reload();
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    console.log('Is user logged in:', isLoggedIn, userUid);


    // ------------------------------------- Animations -------------------------------------
    // Animations for the mobile view
    const mountedStyle = {
        animation: "inAnimation 0.5s ease-in-out",
        animationFillMode: "forwards"
    };
    const unmountedStyle = {
        animation: "outAnimation 0.5s ease-out",
        animationFillMode: "forwards"
    };


    return (
        <div className="navWrapper">
            <div className="Nav NavBarMain">
                <div>
                    <a href = "/" className={`NavLink ${isMobileMenuOpen === true && "d-none"}`}>
                        <div className="d-flex justify-content-end">
                            <img src={logoImage} alt='logo' className="NavLogo" />
                        </div>
                            <div className="font-2 ps-2 fontLogo">mycustomframe</div>
                        </a>
                </div>
                
                {isMobileMenuOpen === false && 
                    <FaBars className="Bars" onClick={() => { 
                        setIsMobileMenuOpen(!isMobileMenuOpen); 
                        if (!showDiv) { setShowDiv(true) }
                    }}/>
                }

                <div className="NavMenu col">
                    {/* <a href="/products" className="NavLink">
                        <div className="NavLink">framing ideas</div>
                    </a> */}
                </div>

                <div className="NavBtn mobileHidden">

                    {!isLoggedIn && ( <div className="button font-2 NavBtnLink NavLogButton" onClick={toggleLoginForm}>log in</div> ) }
                    {useLogin && <LoginForm handleClose={toggleLoginForm} verifyReminder={toggleVerifyReminder}/> }
                    {useVerifyReminder && <VerifyEmail handleClose={toggleVerifyReminder} />}

                    {isLoggedIn && ( <div className="NavBtnLink AccountIcon" onClick={useAccountSettings}> <PersonIcon/> </div> ) }

                    <div className="NavBtnLink ShoppingCart" onClick={toggleShoppingCart}>
                        <ShoppingCartIcon/>
                    </div>

                    <ShoppingCart open={useShoppingCart} handleClose={toggleShoppingCart}/>

                    {isLoggedIn && ( <div className="ps-4 NavBtnLink" onClick={handleLogout}><LogoutIcon /> </div> ) }
                </div>
            </div>

            {isMobileMenuOpen === true &&
                <div className={`transitionDiv NavMobileBG overflow-hidden`} style={isMobileMenuOpen ? mountedStyle : unmountedStyle} >

                    {isMobileMenuOpen === true &&
                        <CloseIcon className="Close text-black" fontSize={"large"} onClick={() => {
                            setIsMobileMenuOpen(!isMobileMenuOpen)
                            if (showDiv){ setShowDiv(false) }
                        }}/>
                    }

                    {useLogin && <MobileLogin handleClose={toggleLoginForm} verifyReminder={toggleVerifyReminder}/> }


                    <div className="text-center ShoppingCart" onClick={toggleShoppingCart}>
                        <ShoppingCartIcon className="CartIconSize"/>
                    </div>

                    <ShoppingCart open={useShoppingCart} handleClose={toggleShoppingCart}/>

                    <div className="mobileWrapper">

                        <a href="/visualizationTool" className="NavLinkMobile text-center">
                            <div className="NavLinkMobile">build a frame</div>
                        </a>

                        <div className="py-5"/>
                        <div className="py-5"/>
                        <div className="py-5"/>
                        <div className="py-5"/>
                        <div className="py-2"/>


                        <div className="NavBtn pt-5 text-center">
                            <div className="pb-2">
                                <button className="btn font-3 NavBtnLink px-5" onClick={toggleLoginForm}>login</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    );
};

export default Navbar;
