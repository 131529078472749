import React from 'react';
import '../App.css';

// Images
import logoImage from '../images/FooterLogo.png';

// Social Icons
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

// Contact Us Icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';


const Footer = () => {
    return (
        <div>
            <div className='ps-3 pt-2'>
                <div className="row footerWrapper justify-content-md-center">
                <div className="col-sm-1"></div>
                    
                    <div className="col-12 col-md-6 py-4">
                        <div className='row d-flex justify-content-center'>
                            <div className="col-4">
                                <div className="footerSubTitle pb-2">shop</div>
                                <a href="/visualizationTool">
                                    <div className="font-2 pb-1 Link">frames</div>
                                </a>
                                {/* <a href="/artist-gallery">
                                    <div className="font-2 pb-1 Link">artist alley</div>
                                </a> */}
                            </div>
                            <div className="col-4">
                                <div className="footerSubTitle pb-2">resources</div>
                                <a href="/order-form">
                                    <div className="font-2 pb-1 Link">custom requests</div>
                                </a>
                            </div>
                            {/* <div className="col-4">
                                <div className="footerSubTitle pb-2">company</div>
                                <a href="/about">
                                    <div className="font-2 pb-1 Link">about us</div>
                                </a>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-6 col-md-3">
                        <div className="row pt-3 ps-2">
                            <div className="d-flex footerTitle text-black pb-1">contact us</div>
                            <div className="pt-1 bg-danger bg-gradient w90" />
                            <div className="py-1 pt-4 contactDetails"><PhoneIcon className='socialIcons SocialIconSize'/> 407-816-7554</div>
                            <div className="py-1 contactDetails"><EmailIcon className='socialIcons SocialIconSize'/>      info@ngfdistributors.com</div>
                            <div className="py-1 contactDetails"><LocationOnIcon className='socialIcons SocialIconSize'/> PO Box 622062 Oviedo, FL 32762</div>
                        </div>
                    </div>



                    <div className="col-6 col-md-2">
                        <div className = "d-flex justify-content-center footerLogoPadding">
                            <div className = "footerLogoWrapper">
                                <img src={logoImage} alt="logo" loading="lazy"/>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center socialIcons">
                            <div className="SocialIconSize">
                                <TwitterIcon className='col' fontSize='large'/>
                            </div>
                            <div className="SocialIconSpacing SocialIconSize">
                                <FacebookIcon className='col' fontSize='large'/>
                            </div>
                            <div className=" SocialIconSize ">
                                <InstagramIcon className='col' fontSize='large'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center bg-dark text-white font-1"> ©2024 MyCustomFrame. All Rights Reserved Web Design by SD8</div>
        </div>
    );
};

export default Footer;
